

.FormContainer{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
    border-radius: 5px;
}
.scoreWrapper{
    display: flex;
   justify-content: center;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    padding: 3.5rem;
}
.showScore{
    width: 50%;
    display: flex;
   justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    flex-direction: column;
}
.ScoreContent{
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background-color: rgb(214, 214, 214);
    flex-direction: column;
    padding: 1rem;
    height: 30rem;
    width: 30rem;
}
.headingScore{
    margin: 1rem;
}
.ScoreContent .headingScore h1{
    font-size: 3rem;
    color: #000;
    font-weight: bold;
    text-align: center;
}
.ScoreContent h1{
    color:rgba(234, 126, 51, 1);
    font-size: 10rem;
    text-align: center;
    font-weight: bold;
}

.ScoreInPer{
    padding: 4rem 6rem;
    
}
.ScoreInPer h1{
    font-size: 2rem;
    line-height: 2.2rem;
    text-align: center;
    text-transform: capitalize;
}
.CorrectQuesWrapper{
    width: 50%;
    max-height: 100%;
   overflow: auto;
   align-self: center;
}
.allQues{
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    border: 2px solid #ddd;
}
.CorrectQuesWrapper::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.allQues h1{
    text-transform: capitalize;
    font-size: 2rem;
   
}
.correct{
    color: rgba(55, 223, 102, 1);
    font-weight: bold;
}
.Incorrect{
    color:rgba(223, 55, 55, 1) ;
    font-weight: bold;
}