.PictureChoice{ 
    width: 100%;
   
   display: grid;
   justify-items: center;
   grid-template-columns: repeat(4,1fr);
   grid-gap:auto;

}
.cardChoice{
    width: 19rem;
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15),0px 0px 15px 0px rgba(180, 180, 180, 0.25) inset;
border-radius: 5px;
cursor: pointer;
}
.cardChoiceSelected{
    background-color:rgba(255, 147, 72, 1);
}
.cardImage{
    width: 100%;
    height: 100%;
}
.cardImage img{
    width: 100%;
    height: 100%;
}
.SelectPictureCard{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    color: #000;
}
.SelectedPictureCard{
    color:#fff;
}
.SelectPictureCard p{
    margin: 0;
    font-size: 1.5rem;
    text-transform: capitalize;
}
.SelectPictureCard span{
    color: #000;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: rgba(223, 223, 223, 1);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.SelectPictureCardIcon{
    background-color: #fff !important;
}
.selectionRight{
    color: #fff;
    background-color:rgba(96, 173, 83, 1);
  }
  .selectionRightIcon{
    color: #fff !important;
    background: rgba(55, 223, 102, 1) !important;
  }
  .selectionWrong{
    color: #fff;
    /* background: rgba(255, 165, 173, 1); */
    background: rgb(251 55 55) !important;
  
  }
  .selectionWrongIcon{
    color: #fff !important;
    background: rgba(223, 55, 55, 1) !important;
  }