.RatingContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.RatingContainer::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in transparent */
.RatingContainer::-webkit-scrollbar-thumb {
    background: transparent;
}
.RatingFruit{
    width: 35rem;
    padding: 2rem; 
    margin: 2rem;
    font-size: 2rem;
    text-transform: capitalize;
    border-radius: 2rem;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15),
    0px 0px 10px 0px rgba(180, 180, 180, 0.25) inset;
  cursor: pointer;
  background-color: #fff;
}