.positionBtn {
  /* transform: translateY(-150%); */
  width: 192.35px;
  height: 71px;
  position: absolute;
  left: 52%;
  top: 68%;
  transform: translate(-50%, -100%);
  font-size: 28px;
  font-weight: 700;
}
.CloseViewEmailSlide {
  position: fixed;
  top: 1%;
  right: 1%;
  width: 7rem;
  /* height: 5rem; */
  fill: #000;
  text-shadow: 0px 14px 15px rgb(0 0 0 / 40%);
  cursor: pointer;
  z-index: 999;
}
.aboutImg {
  width: 65%;
}
/* @media screen and (max-width: 1365px) {
  .aboutImg {
    width: 350px;
  }
  .positionBtn {
    transform: translateY(-50%);
    font-size: 1.2rem;
    padding: 1.3rem 2.8rem;
  }
} */

/* @media screen and (max-width: 1350px) {
  .positionBtn {
    transform: translate(-50%, 180%);
  }
} */

@media screen and (max-width: 1250px) {
  .positionBtn {
    top: 72%;
  }
}
@media screen and (max-height: 719px) {
  .aboutImg {
    width: 350px;
  }
  .positionBtn {
    /* top: 80%; */
    transform: translateY(100%);
    font-size: 1rem;
    padding: 1rem 2rem;
    width: 8rem;
    height: 2rem;
  }
  .CloseViewEmailSlide {
    position: fixed;
    right: 10%;
  }
}
