.wrapper{
    background-color: #000;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.togglePause{
    position: absolute;
    bottom: 10%;
    cursor: pointer;
    height:4.8rem;
    width:13rem;
    background: #EA7E33;
    box-shadow: 0px 5.16364px 18.0727px rgba(0, 0, 0, 0.25);
    border-radius: 35.5px;
    border:0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.togglePause>img{
    max-height: 2rem;
}
video{
    height: 70rem;
}
