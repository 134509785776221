@keyframes loader {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  .loader div {
    position: absolute;
    width: 90px;
    height: 90px;
    border: 15px solid #F17922;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .loader div {
    animation: loader 1s linear infinite;
    top: 100px;
    left: 100px
  }
  .loadingio {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
  }
  .loader {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .loader div { box-sizing: content-box; }