.positionBtn2 {
  transform: translateY(-150%);
  padding: 1.3rem 5.2rem;
  font-size: 1.75rem;
}
.aboutImg2 {
  width: 65%;
}
.CloseViewEmailSlide {
  position: fixed;
  top: 1%;
  right: 1%;
  width: 7rem;
  /* height: 5rem; */
  fill: #000;
  text-shadow: 0px 14px 15px rgb(0 0 0 / 40%);
  cursor: pointer;
  z-index: 999;
}
@media screen and (max-width: 1365px) {
  /* .aboutImg2{
      width: 700px;
    } */
  .positionBtn2 {
    transform: translateY(-150%);
    padding: 1.3rem 4.2rem;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1100px) {
  /* .aboutImg2{
      width: 700px;
    } */
  .positionBtn2 {
    transform: translateY(-150%);
    padding: 1.3rem 4.2rem;
    font-size: 1.2rem;
  }
}
@media screen and (max-height: 719px) {
  .aboutImg {
    width: 350px;
  }
  .positionBtn2 {
    transform: translateY(-100%);
    font-size: 1rem;
    padding: 1rem 2rem;
  }
  .CloseViewEmailSlide {
    position: fixed;
    right: 10%;
  }
}
