.swal-title{
    color: #000!important;
    font-size: 1.75rem;
}
.swal-footer{
    text-align: center;
}

.swal-button{
    background-color: #EA7E33;
    transform: scale(1);
    /* transition: 0.5s; */
    border-radius: 2rem;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.swal-button:not([disabled]):hover {
    background-color: #EA7E33;
    transform: scale(1.1);
    z-index: 2;
}

.swal-button:active {
    background-color: #EA7E33;
    transform: scale(1);
}
.swal-button:focus {
    outline: none;
    border: none;
    box-shadow: none;
}