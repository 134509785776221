.pulse {
  animation: pulse-animation 2s infinite;
  border-radius: 50%;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px  rgba(241, 121, 34, 0.5);
    
  }
  100% {
    box-shadow: 0 0 0 20px rgba(241, 121, 34, 0.1);
  }
}