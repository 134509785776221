.TunelVideoPlayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width:100%;
    height:100%;
    z-index: 20;
    object-fit: cover;
    /* opacity: 0;  */
    /* animation: fadeIn 0.75s ease-out; */
    /* animation-delay: 0.15s;  */
}

.TunelPercentage{
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 20;
    object-fit: cover;
    margin:0 auto;
    display: flex;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    margin-top:1.5rem
}

.fadeOut {
    animation: fadeOut 1s ease-out;
}

@keyframes fadeOut {
    0%{opacity:1}
    100%{opacity:0}
}