/* https://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	/* font-size: 12px; */
	/* font: inherit; */
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, main {
	display: block;
}
body {
  overflow: hidden;
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* --------------------------------

Primary style

-------------------------------- */

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  /* you need to set this to assign to the main element a min height of 100% */
  
  height: 100%;
}

body {
  /* font-size: 100%; */
  font-family: "Roboto", sans-serif;
  color: white;
  background-color: #ededed;
}

a {
  color: #72d69a;
  text-decoration: none;
  transition: color .2s ease-out;
}

.img-replace {
  /* replace text with a background-image */
  
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}
/* --------------------------------

Main styles

-------------------------------- */

.main-content {
  /* set a min-height and a z-index to be sure that the main element completely covers the lateral menu */
  
  min-height: 100%;
  position: relative;
  background-color: #ededed;
  z-index: 2;
  padding-top: 50px;
  /* Force Hardware Acceleration in WebKit */
  
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.main-content.menu-open {
  /* translate to show the lateral menu - all content needs to be put in the .main-content to translate*/
  
  -webkit-transform: translateX(-20rem);
  -moz-transform: translateX(-20rem);
  -ms-transform: translateX(-20rem);
  -o-transform: translateX(-20rem);
  transform: translateX(-20rem);
}

@media only screen and (min-width: 768px) {
  .main-content {
    padding-top: 70px;
  }
}

header {
  position: fixed;
  top: 0;
  /* 40px; */
  left: 0;
  height: 50px;
  width: 10vh;
  /* background: #f9f9f9; */
  z-index: 15;
  /* Force Hardware Acceleration in WebKit */
  /* display: none; */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

header.menu-open {
  /* translate to show the lateral menu */
  
  /* -webkit-transform: translateX(-20rem);
  -moz-transform: translateX(-20rem);
  -ms-transform: translateX(-20rem);
  -o-transform: translateX(-20rem);
  transform: translateX(-20rem); */
  z-index: 16;
}

@media only screen and (min-width: 768px) {
  header {
    height: 50px;
  }
}
#vCall{
  position:absolute;height:96%;right: 0;top:2%;width: 20%;border:0;display:none;transition: 250ms;
}
#site-title {
  font-family: Proxima Nova A;
  font-style: normal;
  font-weight: normal;
  font-size: 12.6724px;
  line-height: 92.9%;
  cursor: pointer;
  display: none;
  justify-content:center;
  align-items:center;
  color: #ffffffbe;
  float: left;
  /* display: block;
  
  margin: 12px 0 0 20px;
  font-size: 1.5em;
  font-weight: 400; */
}

#site-title a {
  color: #585858;
}

#site-title a:hover {
  color: #b6b6b6;
}

#site-title img {
  display: block;
}

@media only screen and (min-width: 768px) {
  #site-title {
    margin: 2rem 0 0 2rem;
  }
}

#nav-trigger {
  position: relative;
  margin: 1rem 0rem 0 1rem;
  top: auto;
  left: auto;
  width: 5vh;
  height: 3.5vh;
  cursor: pointer;
  text-align: left;
  float: left;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -moz-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

#nav-trigger .line {
  height: 2px;
  width: 100%;
  margin-bottom: 6px;
  background: white;
  -moz-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

#nav-trigger .line.l-01 {
  width: 70%;
  /* 95%; */
}

#nav-trigger .line.l-02 {
  width: 70%;
  /* 80%; */
}

#nav-trigger .line.l-03 {
  width: 70%;
}

#nav-trigger .line.l-04 {
  width: 100%;
}

#nav-trigger:hover .line.l-01 {
  width: 60%;
}

#nav-trigger:hover .line.l-02 {
  width: 70%;
}

#nav-trigger:hover .line.l-03 {
  width: 80%;
}

#nav-trigger:hover .line.l-04 {
  width: 70%;
}

#nav-trigger.is-clicked .line.l-01,
#nav-trigger.is-clicked .line.l-04 {
  background-color: transparent;
}

#nav-trigger.is-clicked .line.l-02,
#nav-trigger.is-clicked .line.l-03 {
  width: 70%;
}

#nav-trigger.is-clicked .line.l-02 {
  margin-top: 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-trigger.is-clicked .line.l-03 {
  margin-top: -8px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#menu-trigger {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50px;
}

#menu-trigger .menu-text {
  height: 100%;
  text-transform: uppercase;
  color: #585858;
  display: none;
}

#menu-trigger .menu-icon {
  /* this span is the central line in the menu menu */
  
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  left: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 18px;
  height: 2px;
  background-color: #585858;
  /* these are the upper and lower lines in the menu menu */
}

#menu-trigger .menu-icon::before,
#menu-trigger .menu-icon:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: inherit;
  left: 0;
}

#menu-trigger .menu-icon::before {
  bottom: 5px;
}

#menu-trigger .menu-icon::after {
  top: 5px;
}

#menu-trigger.is-clicked .menu-icon {
  background-color: transparent;
}

#menu-trigger.is-clicked .menu-icon::before,
#menu-trigger.is-clicked .menu-icon::after {
  background-color: #585858;
}

#menu-trigger.is-clicked .menu-icon::before {
  bottom: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#menu-trigger.is-clicked .menu-icon::after {
  top: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media only screen and (min-width: 768px) {
  #menu-trigger {
    width: 110px;
    padding-left: 1.25em;
  }
  #menu-trigger .menu-text {
    display: inline-block;
    line-height: 74px;
  }
  #menu-trigger .menu-icon {
    right: auto;
    left: 1.25em;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
.linebreak{
    height: 1.5px;
    width: 85%;
    margin: 0rem 1.3rem;
    background: #ffffff38;
}
#side-nav {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  visibility: hidden;

  padding-left: 20px;
  padding-right: 20px;

  /* the secondary navigation is covered by the main element */
  
  z-index: 15;
  width: auto;
  /* 350px; */
  background-color: rgb(0 0 0 / 45%);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  overflow-y: auto;
  /* Force Hardware Acceleration in WebKit */
  
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform .4s 0s, visibility 0s .4s;
  -moz-transition: -moz-transform .4s 0s, visibility 0s .4s;
  transition: transform .4s 0s, visibility 0s .4s;
  /* this creates the subtle slide in animation of the navigation */
  
  -webkit-transform: translateX(-370px);
  -moz-transform: translateX(-370px);
  -ms-transform: translateX(-370px);
  -o-transform: translateX(-370px);
  transform: translateX(-370px);
}
.navigation{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 60px);
    align-items: center;
    margin: 60px 0px 0 0 !important;
}
#side-nav .navigation {
  margin: 60px 0 16px;
}

#side-nav .sub-menu {
  padding: 0 10px 20px 15px;
  display: none;
}


#side-nav a {
  display: block;
  line-height: 1.2rem;
  padding: 2rem 32px 0 32px;
  color: white;
}

#side-nav a.current {
  color: #72d69a;
}

.no-touch #side-nav a:hover {
  color: #72d69a;
}

@media only screen and (min-width: 768px) {
  #side-nav .navigation {
    margin: 60px 0;
  }
}

#side-nav.menu-open {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  -webkit-transition: -webkit-transform .4s 0s, visibility 0s 0s;
  -moz-transition: -moz-transform .4s 0s, visibility 0s 0s;
  transition: transform .4s 0s, visibility 0s 0s;
  /* smooth the scrolling on touch devices - webkit browsers */
  
  -webkit-overflow-scrolling: touch;
}
/* style menu items which have a submenu  */

#side-nav .item-has-children > a {
  position: relative;
  font-size: 1.2em;
}

#side-nav .item-has-children > a::after {
  content: '';
  width: 8px;
  height: 8px;
  border-left: solid 2px white;
  border-bottom: solid 2px white;
  position: absolute;
  z-index: 5;
  top: 76%;
  left: 87%;
  display: block;
  transition-property: transform;
  transition-duration: 0.2s;
  transform: rotate(-135deg);
}

#side-nav .item-has-children > a.submenu-open::after {
  transform: translateY(-25%) rotate(-45deg);
}

#side-nav p {
  font-size: 1em;
  line-height: 1.5;
  padding: 0 16px 0 32px;
}

#side-nav p a {
  padding: 0;
}

#side-nav .socials {
  padding: 0 32px;
}

#side-nav .socials:after {
  content: "";
  display: table;
  clear: both;
}

#side-nav .socials a {
  display: inline-block;
  font-size: 1.5em;
  line-height: 72px;
  padding: 0 .5em 0 0;
}

.sidePanelBtn{
  color: white !important;
  font-size: 1rem !important;font-weight: normal;
  cursor: pointer;
}

.sidePanelBtn:hover{
  color: #FF6D00  !important;
  /* font-weight: normal !important; */
  /* font-size: 1.1rem; */
  text-shadow: 0px 3px 12px rgb(0 0 0 / 25%);
  transition: 250ms;
}

