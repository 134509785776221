.wrapper{
    background-color: #fff;
    height: 100vh;
    width: 100%;
    overflow:auto;
    font-family: 'Proxima Nova A', sans-serif;
}
.insight{
    width: 10rem;
    height: 5rem;
    background: #3A2469;
    color:#fff;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    margin-bottom:3rem;
}
.heading{
    font-size: 4rem;
    color: #3A2469;
    font-weight: 700;
    padding-bottom: 1rem;
}
.subHeading{
    font-size: 2.5rem;
    color: #8F5AFF;
}
.insight,
.heading,
.subHeading{
    font-family: 'Proxima Nova A', sans-serif;
    margin-left:3rem;
}
.container{
    margin-top:1.7rem;
    background: #8F5AFF;
    height: 65%;
}
.container>.subHeading{
    color: #fff;
    padding-top:1.5rem;
    font-weight: 400;
    line-height: 3rem;
    padding-bottom: 4rem;
}
.patientContainer{
    display: flex;
    justify-content: space-evenly;
}
.patientImg{
    position: relative;
    user-select: none;
}
.cta{
    position:absolute;
    color:#fff;
    font-size: 1.7rem;
    font-weight: 500;
    background: #EA7E33;
    box-shadow: 0px 5.16364px 18.0727px rgba(0, 0, 0, 0.25);
    border-radius: 35.5px;
    padding: 2rem 5rem;
    bottom: -8%;
    left: 50%;
    transform: translate(-50%);  
    cursor: pointer;
    transition: all 0.3s;
}
.cta:hover{
    transform: translate(-50%) scale(1.01);
}
@media screen and (max-width: 1550px) {
    .patientImg{
        width: 40vw;
    }
}
@media screen and (max-width: 900px) {
    .patientContainer{
        flex-direction:column;
        align-items: center;
        height: 100%;
        justify-content: flex-start;
        grid-gap: 6rem;
    }
}
@media screen and (max-width: 700px) {
    .container{
        height: 75%
    }
    .patientContainer{
        flex-direction:column;
        align-items: center;
        height: 100%;
        justify-content: flex-start;
        grid-gap: 6rem;
    }
    .patientImg{
        width: 60vw;
    }
}
@media screen and (max-width: 500px) {
    .container{
        height: 70%
    }
    .patientImg{
        width: 75vw;
    }
}
@media screen and (max-width: 450px) {
    .container{
        height: 80%
    }
}