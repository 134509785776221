.multipleChoiceWrapper {
  margin-top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap:2rem;
  align-content: center;
  justify-content: center;
  padding: 2rem;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}
.multipleChoiceContent {
  padding:1rem;
  width: 100%;
  min-height: 7rem;
  position: relative;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 18px 0px rgb(0 0 0 / 15%), 0px 0px 18px 0px rgb(180 180 180 / 25%) inset;
}
.selectedMultipleChoiceContent{
  background-color:rgba(234, 126, 51, 1);
}
.multipleChoiceContent input {
  position: absolute;
  width: 100%;
  min-height: 7rem;
  opacity: 0;
  cursor: pointer;
  margin: 0;
}
.selectContent {
    width: 100%;
    height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.8rem;
}
.selectedContent{
  color: #fff;
}
.selectContent p{
  width: 85%;
  font-size: 2rem;
  line-height: 2rem;
}

.selectContent span {
  color: #000;
  width: 2rem;
  height: 2rem;
  padding: 0.1rem;
  border-radius: 50%;
  background: rgba(223, 223, 223, 1);
  font-size: 1.5rem;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.selectedArrow{
  background-color: #fff !important;
}
.selectionRight{
  background-color:rgba(96, 173, 83, 1);
}
.selectionRightIcon{
  color: #fff !important;
  background: rgba(55, 223, 102, 1) !important;
}
.selectionWrong{
  /* background: rgba(255, 165, 173, 1); */
  background: rgb(251 55 55) !important;

}
.selectionWrongIcon{
  color: #fff !important;
  background: rgba(223, 55, 55, 1) !important;
}