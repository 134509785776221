
  .spatialMicConatiner{
    position: absolute;
    top: auto;
    bottom: 5.5rem;
    background: #C4C4C4;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 12rem;
    padding: 0.75rem 0.75rem;
    padding-left: 1.25rem;
    font-size: 1.25rem;
    border-bottom-right-radius: 3rem;
    border-top-right-radius: 3rem;
    color: black;
    z-index: 2;
    animation: slide-In 2s forwards ;
  }


  .spatialMicConatiner_hide{
    display: none;
  }
  
  #spatialMic{
    /* position: absolute; */
    /* bottom: 2.15rem; */
    /* left: 12.5rem; */
    width: 3.5rem;
    height: 3.5rem;
    background: #818181;
    box-shadow: 0px 0px 14.5213px rgba(0, 0, 0, 0.25);
    padding: 0.75rem;
    border-radius: 50%;
    margin-left: 0.75rem;
  }

  @keyframes  slide-In{
    0%{left: -20rem;opacity: 0;}  
    100%{left: 0;opacity: 1;}
  }