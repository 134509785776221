.navBar{
  position: absolute;
  top: 0;
  width: 100%;
  display: grid;
  background-color: #fff;
  grid-template-columns:34% 66%;
  gap: 2rem;
  align-items: center;
  padding: 0 2rem;
  height: 10vh;
  z-index: 1;
  user-select: none;
}
.navBar h1{
font-size: 4.5rem;
color: #383245;
}
.navOptions{
 display: flex;
 align-items: center;
 gap: 2rem;
}
.navOptions p{
 font-size: 1.4rem;
 color: #575756;
 line-height: 1.6;
 max-width: 59%;
}
.navOptions button{
 height: 4.5rem;
 text-align: center;
 font-size: 1.8rem;
 border: 3px solid #EC7200;
 display: flex;
align-items: center;
justify-content: center;
gap: 1rem; 
cursor: pointer;

}
.CircleBtn{
 width: 4.5rem;
 border-radius: 50%;
 box-shadow: 0 0 10px rgba(0,0,0,0.4);
 color: #fff;
 background-color: #EC7200;
}
.CircleBtn.active{
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(54px);
 color: #EC7200;
 font-weight: 600;
}
.OFFBtn{
padding: 0 1.2rem;
 border-radius: 26px;
 box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
 color: #fff;
 background-color: #EC7200;
 font-weight: 600;
}
@media only screen and (max-width:1560px){
  .navBar h1{
    font-size: 3.5rem;
    
    }
}
@media only screen and (max-width:1370px){
  .navBar h1{
    font-size: 3rem !important;
    }
    .navBar{
     
     grid-template-columns:30% 70%;
    }
    .navOptions p{
      font-size: 1.2rem;
      color: #383245;
      line-height: 1.6;
      max-width: 45%;
     }
}