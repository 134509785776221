.wrapper{
    background-color: #000;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}
.patientsWrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba(143, 90, 255, 0.6);

    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
     opacity: 1;
}
.patients{
    width: 118rem;
    height: 48rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 45px;
    color: #000;
    user-select: none;
    padding: 6rem;
    position: relative;
}
.playButton{
    color: #fff;
    font-size: 1.7rem;
    position: absolute;
    bottom: 3rem;
    height:4.8rem;
    width:13rem;
    cursor: pointer;
    background: #EA7E33;
    box-shadow: 0px 5.16364px 18.0727px rgba(0, 0, 0, 0.25);
    border-radius: 35.5px;
    border:0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.lightLogo{
    position: absolute;
    top: -11%;
    width: 12rem;
}
.playButton img{
    width: 10%;
    margin: auto;
}
.patients h1{
    font-size: 2.2rem;
    line-height: 1.2;
}