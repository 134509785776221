.audio1 {
  position: absolute;
  top: 38%;
  left: 19%;
  /* transform: scale(1.2); */
  cursor: pointer;
  box-shadow: 60px 0px 30px #f17922 inset,
    0px 5.16364px 18.0727px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 110px;

  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  opacity: 0;
}
.audio2 {
  position: absolute;
  top: 38%;
  left: 50%;
  /* transform: scale(1.2); */
  box-shadow: 60px 0px 30px #f17922 inset,
    0px 5.16364px 18.0727px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
  width: 110px;
}
.footer {
  display: flex;
  position: absolute;
  bottom: 3%;
  left: 15%;
  gap: 2rem;
}
.bubbleText {
  width: 100%;
  height: 100%;
}

/* .bubbleText img{
  width: 18rem;
} */

.docText {
  margin-top: -23%;
  margin-left: -2%;
  position: relative;
  z-index: 9;
  width: 22rem;
  top: 55%;
  left: 45%;
}
.converstation {
  position: relative;
  z-index: 9;
  top: 53%;
  left: 45%;
  width: 26rem !important;
  margin-top: -11%;
  margin-left: -38%;

  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  opacity: 0;
}
@media screen and (max-width: 1440px) {
  .bubbleText img {
    width: 13rem;
  }
  .docText {
    position: relative;
    z-index: 9;
  }
  .converstation {
    width: 20rem !important;
    margin-top: -9%;
    margin-left: -35%;
  }
  .audio2,
  .audio1 {
    width: 90px;
  }
}

@media screen and (max-width: 1136px) {
  .docText {
    position: relative;
    z-index: 9;
    top: 50%;
  }
  .converstation {
    width: 20rem !important;
    margin-top: -9%;
    margin-left: -35%;
    top: 45%;
  }
}

@media screen and (max-width: 768px) {
  .docText {
    width: 15rem !important;
  }
  .converstation {
    width: 15rem !important;
  }
}

@media screen and (max-height: 719px) {
  .bubbleText img {
    width: 12rem;
  }
  .docText {
    margin-top: -26%;
    margin-left: -5%;
    position: relative;
    z-index: 9;
  }
  .converstation {
    margin-top: -6%;
    margin-left: -37%;
  }
  .audio2,
  .audio1 {
    width: 80px;
  }
}
